import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { AlertTitle, Button, Slide, SlideProps, Snackbar } from "@mui/material";
import React, { Fragment, ReactElement } from "react";

import {
  INFO_ALERT_SEVERTIY_TYPES,
  INFO_PANEL_DURATION_MS,
} from "../../utils/constants/constants";
import { StyledAlert, StyledCloseIcon } from "./InformationAlert.styles";

type Props = {
  id: string;
  open: boolean;
  title?: string | undefined;
  text: string;
  severity: INFO_ALERT_SEVERTIY_TYPES;
  handleClose: any;
  action?: any;
  actionTitle?: string;
};

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}

const InformationAlert = (props: Props): ReactElement => {
  const {
    id,
    open,
    title = undefined,
    text,
    handleClose,
    severity,
    action = undefined,
    actionTitle = "Action",
  } = props;

  const actionBlock = (
    <Fragment>
      {action && (
        <Button
          id={`${id}_action`}
          color="inherit"
          size="small"
          onClick={action}
        >
          {actionTitle}
        </Button>
      )}

      <StyledCloseIcon
        id={`${id}_close`}
        fontSize="small"
        onClick={handleClose}
      />
    </Fragment>
  );

  return (
    <Snackbar
      id={id}
      open={open}
      autoHideDuration={INFO_PANEL_DURATION_MS}
      onClose={handleClose}
      message={text}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      TransitionComponent={SlideTransition}
    >
      <StyledAlert
        onClose={handleClose}
        severity={severity}
        action={actionBlock}
        variant="standard"
        iconMapping={{
          success: <CheckCircleOutlineIcon fontSize="inherit" />,
          warning: <WarningAmberOutlinedIcon fontSize="inherit" />,
          info: <InfoOutlinedIcon fontSize="inherit" />,
          error: <ErrorOutlineOutlinedIcon fontSize="inherit" />,
        }}
      >
        {title && <AlertTitle>{title}</AlertTitle>}
        {text}
      </StyledAlert>
    </Snackbar>
  );
};

export default InformationAlert;
