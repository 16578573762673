import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

import MenuIcon from '@mui/icons-material/Menu';

import { useResponsive } from 'utils/hooks/use-responsive';

// import Searchbar from './common/searchbar';
import { NAV, HEADER } from './component/config-layout';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router';
import { imagePath } from 'utils/helpers';
import DUMMY_PROFILE from 'assets/images/profile/profile.jpeg';
// import AccountPopover from './common/account-popover';
// import LanguagePopover from './common/language-popover';
// import NotificationsPopover from './common/notifications-popover';

// ----------------------------------------------------------------------
type IProp = {
  onOpenNav: () => void;
  account?: {
    photoURL?: string | null;
    displayName: string | undefined;
    role: string | undefined;
  };
};

export default function Header({ account, onOpenNav }: IProp) {
  const theme = useTheme();

  const lgUp = useResponsive('up', 'lg', 'lg');
  const navigate = useNavigate();

  const renderContent = (
    <>
      {!lgUp && (
        <IconButton onClick={onOpenNav} sx={{ mr: 1 }}>
          <MenuIcon fontSize={'large'} />
        </IconButton>
      )}

      {/* <Searchbar /> */}

      <Box sx={{ flexGrow: 1 }} />

      <Stack direction="row" alignItems="center" spacing={1}>
        {/* <LanguagePopover /> */}
        {/* <NotificationsPopover /> */}
        {/* <AccountPopover /> */}
        <Box
          sx={{
            my: 3,
            mx: 2.5,
            py: 2,
            px: 2.5,
            display: 'flex',
            borderRadius: 1.5,
            alignItems: 'center'
          }}
        >
          <Box sx={{ display: 'flex', mr: 2, gap: 2 }}>
            {/* <Typography
              variant="h5"
              sx={{
                color: 'text.primary',
                backgroundColor: theme.palette.background.default,
                borderRadius: '10%',
                padding: 1
              }}
            >
              {account?.role}
            </Typography> */}
            <Typography
              variant="h3"
              sx={{ paddingTop: 1, color: 'text.primary' }}
            >
              {account?.displayName}
            </Typography>
          </Box>
          <Box sx={{ cursor: 'pointer' }} onClick={() => navigate('/profile')}>
            <Avatar>
              <img
                crossOrigin="anonymous"
                src={imagePath(account?.photoURL || null)}
                alt="photoURL"
                onError={(event: any) => {
                  event.target.src = DUMMY_PROFILE;
                  event.target.alt = 'dummy profile';
                }}
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                  objectFit: 'contain'
                }}
              />
            </Avatar>
          </Box>
        </Box>
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.WIDTH + 1}px)`,
          height: HEADER.H_DESKTOP
        }),
        backgroundColor: theme.palette.common.white
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 }
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
