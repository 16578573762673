import React from "react";
import { ErrorBoundary } from "react-error-boundary";

import Routes from "routes";

import { AppProvider } from "core/context/appContextProvider";

import FallbackScreen from "components/Fallback/FallbackScreen";

export default function App() {
  return (
    <AppProvider>
      <ErrorBoundary FallbackComponent={FallbackScreen}>
        <Routes />
      </ErrorBoundary>
    </AppProvider>
  );
}
