import { redirect } from 'react-router-dom';
import {
  TOKEN_KEY,
  DEV_API_URL,
  PROD_API_URL,
  CLIENT_SUBSCRIPTION_EXPIRY
} from '../constants/constants';
import { AccessModule } from 'utils/types';

export const getPaymentKey = (): string => {
  if(window && window.location.host.includes('localhost')) {
    return process.env.REACT_APP_PAYMENT_GATEWAY_TEST_KEY!;
  } else {
    return process.env.REACT_APP_PAYMENT_GATEWAY_PROD_KEY!;
  }
}

export const imagePath = (path: string | null) => {
  if (
    window?.location.host.includes('localhost')
    // ||
    // window?.location.host.includes('admin-vbc.kuldeepakbari.com')
  ) {
    return `${DEV_API_URL.split('/api/')[0]}${path}`;
  } else {
    return `${PROD_API_URL.split('/api/')[0]}${path}`;
  }
};

export const getFormData = (object: any) => {
  const formData = new FormData();
  Object.keys(object).forEach((key) => formData.append(key, object[key]));
  return formData;
};

export const listPayload = (
  page: number,
  where = {},
  rows: number = 10,
  pagination = {}
) => {
  return {
    where: {
      IsDeleted: false,
      ...where
    },
    pagination: {
      sortBy: 'createdAt',
      descending: true,
      rows: rows,
      page: page + 1,
      ...pagination
    }
  };
};

export const setTitleAndSuffix = (title: string) => {
  return `${title} - VB Card`;
};

export const setAuthToken = (token: string) => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const getAuthToken = () => {
  const value = localStorage.getItem(TOKEN_KEY);
  if (value) {
    return value;
  } else {
    return null;
  }
};

export const getIsSubscriptionFlag = () => {
  // console.log("getIsSubscriptionFlag", localStorage.getItem(CLIENT_SUBSCRIPTION_EXPIRY) === 'true');
  return localStorage.getItem(CLIENT_SUBSCRIPTION_EXPIRY) === 'true';
};

export const checkIsSubscriptionExpiry = () => {
  if (getIsSubscriptionFlag()) {
    return redirect('/subscription-plan');
  }
  return null;
};

export const checkIsAuthenticated = () => {
  const value = getAuthToken();
  if (value === null) {
    return redirect('/login');
  }
  return null;
};

export const rightsAccess = (
  accessModules: AccessModule[] | null,
  pathname: string
) => {
  let mainPath = pathname;
  if (accessModules && accessModules.length > 0) {
    const selectedModule: AccessModule | undefined = accessModules.find(
      (res) => res.PX_Module.path === mainPath
    );
    return {
      add: selectedModule?.add || false,
      edit: selectedModule?.edit || false,
      delete: selectedModule?.delete || false
    };
  } else {
    return {
      add: false,
      edit: false,
      delete: false
    };
  }
};
