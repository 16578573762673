import React from 'react';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import RecordVoiceOverOutlinedIcon from '@mui/icons-material/RecordVoiceOverOutlined';
import ArticleIcon from '@mui/icons-material/Article';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';

export type NavConfig = {
  title: string;
  path: string;
  icon: React.ReactNode;
  // children?: [{
  //   title: string;
  //   path: string;
  // }]
};

const navConfig = [
  {
    title: 'dashboard',
    path: '/',
    icon: <HomeOutlinedIcon />
  },
  {
    title: 'master',
    icon: <AppsOutlinedIcon />,
    path: '',
    children: [
      {
        title: 'company',
        path: '/company'
      },
      {
        title: 'users',
        path: '/users'
      },
      {
        title: 'roles',
        path: '/roles'
      },
      {
        title: 'ranks',
        path: '/ranks'
      },
      {
        title: 'rights',
        path: '/rights'
      },
      {
        title: 'modules',
        path: '/modules'
      },
      {
        title: 'bf',
        path: '/bf'
      },
      {
        title: 'gsm',
        path: '/gsm'
      },
      {
        title: 'reel',
        path: '/reel'
      },
      {
        title: 'stock',
        path: '/stock'
      },
      {
        title: 'gsmcategory',
        path: '/gsmcategory'
      },
      {
        title: 'ordertype',
        path: '/ordertype'
      }
    ]
  },
  {
    title: 'customer',
    path: '/customer',
    icon: <RecordVoiceOverOutlinedIcon/>
  },
  {
    title: 'order',
    path: '/order',
    icon: <ShoppingCartIcon />
  },
  {
    title: 'order receive',
    path: '/order-receive',
    icon: <ShoppingCartCheckoutIcon/>
  },
  {
    title: 'report',
    path: '/report',
    icon: <ArticleIcon />
  }
];

export default navConfig;
