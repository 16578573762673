import {
  CLIENT_PROXY_TOKEN_KEY,
  CLIENT_SUBSCRIPTION_EXPIRY,
  TOKEN_KEY
} from 'utils/constants/constants';

const NavHooks = () => {
  const handleLogOut = () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(CLIENT_SUBSCRIPTION_EXPIRY);
    localStorage.removeItem(CLIENT_PROXY_TOKEN_KEY);
    window.location.reload();
  };

  return {
    handleLogOut
  };
};

export default NavHooks;
