import React, {
  useContext,
  useCallback,
  useEffect,
  useState,
  useMemo
} from 'react';
import { useNavigate } from 'react-router-dom';

import theme from 'theme';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { alpha } from '@mui/material/styles';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

import { usePathname } from 'routes/hooks';
import { useResponsive } from 'utils/hooks/use-responsive';
import RouterLink from 'routes/components/router-link';
import Scrollbar from 'components/scrollbar/scrollbar';

import { NAV } from './component/config-layout';
import navConfig from './component/config-navigation';
import NavHooks from './hooks/nav.hook';
import { AppContext } from 'core/context/appContextProvider';
import { AccessModule } from 'utils/types';
import Typography from '@mui/material/Typography';

// ----------------------------------------------------------------------

type IProp = {
  openNav: boolean;
  onCloseNav: () => void;
  account?: {
    photoURL: string;
    displayName: string;
    role: string;
  };
};

export default function Nav({ openNav, onCloseNav, account }: IProp) {
  const { handleLogOut } = NavHooks();
  const pathname = usePathname();
  const { accessModules } = useContext(AppContext);

  const upLg = useResponsive('up', 'lg', 'lg');

  const [open, setOpen] = useState<boolean>(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    
    // eslint-disable-next-line
  }, [pathname]);

  const renderMenu = (
    <Stack component="nav" spacing={0.5} sx={{ py: 2 }}>
      {navConfig &&
        navConfig.length > 0 &&
        navConfig.map((item, index: number) => (
          <NavItem
            key={`nav_item_${index}`}
            icon={item.icon}
            title={item.title}
            path={item.path}
            children={item.children || []}
            isWeb={upLg}
            open={open}
            handleOpen={toggleOpen}
            setOpen={setOpen}
            accessModules={accessModules!}
          />
        ))}
      <ListItemButton
        sx={{
          minHeight: 44,
          borderRadius: 0.75,
          typography: 'h3',
          color: 'text.secondary',
          textTransform: 'capitalize',
          fontWeight: 'fontWeightMedium'
        }}
        onClick={handleLogOut}
      >
        <ListItemIcon
          sx={{
            color: theme.palette.secondary.main
          }}
        >
          <LogoutOutlinedIcon />
        </ListItemIcon>
        <ListItemText>Log Out</ListItemText>
      </ListItemButton>
    </Stack>
  );

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        overflow: 'auto',
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <Box
        sx={{
          textAlign: 'center',
          paddingTop: '1rem',
          backgroundColor: theme.palette.background.default
        }}
      >
        <Box component={RouterLink} href={'/'}>
          <img
            src="/logo2.svg"
            alt="Logo"
            style={{
              maxWidth: '100%',
              height: 'auto',
              background: '#fff',
              borderRadius: '100px',
              padding: '10px 30px'
            }}
          />
        </Box>
      </Box>

      {/* {renderAccount} */}

      {renderMenu}

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.WIDTH }
      }}
    >
      {upLg ? (
        <Box
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.WIDTH,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
            backgroundColor: theme.palette.primary.main
          }}
        >
          {renderContent}
        </Box>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.WIDTH,
              backgroundColor: theme.palette.primary.main
            }
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}

// ----------------------------------------------------------------------

type NavProp = {
  key: string;
  icon: React.ReactNode;
  title: string;
  path: string;
  children: any;
  isWeb: boolean;
  open: boolean;
  handleOpen: () => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  accessModules: AccessModule[];
};

function NavItem({
  key,
  icon,
  title,
  path,
  children,
  isWeb,
  open,
  handleOpen,
  setOpen,
  accessModules
}: NavProp) {
  const pathname = usePathname();
  const navigate = useNavigate();

  const handleClick = () => {
    if (children && children.length > 0) {
      handleOpen();
    } else {
      setOpen(false);
      navigate(path);
    }
  };

  useEffect(() => {
    if (children.length > 0) {
      if (
        children
          ?.map((res: any) => (pathname.includes(res.path) ? true : false))
          .includes(true)
      ) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }
  }, [children, pathname, setOpen]);

  const activeTab = useCallback(
    (path: string) => {
      // console.log(pathname.split('/').splice(1)[0], path.split('/').splice(1));
      if (pathname === path) {
        return true;
      } else if (
        path.split('/').splice(1).includes(pathname.split('/').splice(1)[0])
      ) {
        return true;
      }
      // return (
      //   pathname === path ||
      //   pathname.split('/').splice(1).includes(path.split('/').join(''))
      // );
      // return pathname.split('/').splice(1).includes(path.split('/').join(''));
    },
    [pathname]
  );

  const isShow = useMemo((): boolean => {
    if (path.length === 0) {
      return true;
    }
    // else if (path.includes('reports')) {
    //   return true;
    // }
    else if (accessModules.length > 0) {
      return accessModules
        .map(
          (module: AccessModule) =>
            (module.PX_Module.path === path && module.view) || path === '/'
        )
        .includes(true);
    } else {
      return false;
    }
  }, [accessModules, path]);

  if (isShow) {
    return (
      <Box key={key}>
        <ListItemButton
          onClick={handleClick}
          sx={{
            minHeight: 44,
            borderRadius: 0.75,
            typography: 'h3',
            color: 'text.secondary',
            textTransform: 'capitalize',
            fontWeight: 'fontWeightMedium',
            ...(activeTab(path) &&
              children.length === 0 && {
                color: 'text.primary',
                fontWeight: 'fontWeightSemiBold',
                bgcolor: (theme) => theme.palette.secondary.main,
                '&:hover': {
                  bgcolor: (theme) =>
                    isWeb
                      ? alpha(theme.palette.secondary.main, 0.16)
                      : theme.palette.secondary.main
                }
              })
          }}
        >
          <ListItemIcon
            sx={{
              color: theme.palette.secondary.main,
              ...(activeTab(path) &&
                children.length === 0 && {
                  color: theme.palette.primary.main
                })
            }}
          >
            {icon}
          </ListItemIcon>
          <ListItemText>{title}</ListItemText>
          {children && children.length > 0 ? (
            open ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )
          ) : null}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children &&
              children?.map((option: any, index: number) => {
                if (
                  accessModules
                    .map(
                      (module: AccessModule) =>
                        module.PX_Module.path === option.path && module.view
                    )
                    .includes(true)
                ) {
                  return (
                    <ListItemButton
                      key={`child_${index}`}
                      component={RouterLink}
                      href={option.path}
                      sx={{
                        paddingLeft: theme.spacing(8),
                        minHeight: 44,
                        borderRadius: 0.75,
                        typography: 'h3',
                        color: 'text.secondary',
                        textTransform: 'capitalize',
                        fontWeight: 'fontWeightMedium',
                        ...(activeTab(option.path) && {
                          color: 'text.primary',
                          fontWeight: 'fontWeightSemiBold',
                          bgcolor: (theme) => theme.palette.secondary.main,
                          '&:hover': {
                            bgcolor: (theme) =>
                              isWeb
                                ? alpha(theme.palette.secondary.main, 0.16)
                                : theme.palette.secondary.main
                          }
                        })
                      }}
                    >
                      <Box component="span">{option.title} </Box>
                    </ListItemButton>
                  );
                } else {
                  return null;
                }
              })}
          </List>
        </Collapse>
      </Box>
    );
  } else {
    return null;
  }
}
