import React, { createContext, useEffect, useMemo, useState } from 'react';
import InformationAlert from 'components/InformationAlert/InformationAlert';
import Progress from 'components/Progress/ProgressModal/ProgressModal';
import noop from 'utils/helpers/noop';
import { AccessModule, InformationMessage, LoginUser } from 'utils/types';
import {
  INFO_ALERT_SEVERTIY_TYPES,
  // CLIENT_PROXY_TOKEN_KEY,
  // TOKEN_KEY,
  CLIENT_SUBSCRIPTION_EXPIRY
} from 'utils/constants/constants';
import { getAuthToken } from 'utils/helpers';
import { fetchLoginUser } from 'services/user.service';
import { UserLogin } from 'utils/types/userLogin.type';

interface Iprop {
  children: React.ReactNode;
}

const AppContext = createContext<{
  token: string | null;
  loading: boolean;
  loginUser: LoginUser | null;
  accessModules: AccessModule[] | null;
  informationAlertMessage: InformationMessage;
  onLogin: (loginUser: LoginUser, token: string) => void;
  informationAlert: (
    text: string | undefined,
    severity: INFO_ALERT_SEVERTIY_TYPES,
    title?: string | undefined,
    actionTitle?: string | undefined,
    action?: () => void
  ) => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setInformationAlertMessage: React.Dispatch<
    React.SetStateAction<InformationMessage>
  >;
  fetchLoginUserData: () => void;
  onloginVerify: (loginUser: UserLogin, token: string) => void;  

}>({
  token: null,
  loading: false,
  loginUser: null,
  accessModules: null,
  informationAlertMessage: {
    text: '',
    open: false,
    severity: 'info'
  },
  onLogin: noop,
  setLoading: noop,
  informationAlert: noop,
  setInformationAlertMessage: noop,
  fetchLoginUserData: noop,
  onloginVerify:noop
});

const AppProvider: React.FC<Iprop> = ({ children }) => {
  const [token, setToken] = useState<string | null>(null);
  const [loginUser, setLoginUser] = useState<LoginUser | null>(null);
  const [user, setUser] = useState<UserLogin | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [informationAlertMessage, setInformationAlertMessage] =
    useState<InformationMessage>({
      text: '',
      open: false,
      severity: 'info'
    });

  const onLogin = async (loginUser: LoginUser, token: string) => {
    setToken(token);
    setLoginUser(loginUser);
  };

// login with email passowrd
const onloginVerify = async(user:UserLogin,token:string)=>{
  setToken(token);
  setUser(user);
}

  const informationAlert = (
    text: string | undefined = '',
    severity: INFO_ALERT_SEVERTIY_TYPES = 'info',
    title: string = '',
    actionTitle: string = '',
    action = () => {}
  ) => {
    setInformationAlertMessage({
      text: text,
      severity: severity,
      open: true,
      title: title,
      actionTitle: actionTitle,
      action: action
    });
  };

  const handleInformationAlertMessageClose = () => {
    setInformationAlertMessage({
      text: '',
      severity: informationAlertMessage.severity,
      open: false
    });
  };

  const fetchLoginUserData = async () => {
    try {
      setLoading(true);
      const { success, message, data } = await fetchLoginUser();
      if (success) {
        setLoginUser(data);
      } else {
        informationAlert(message, 'error');
      }
    } catch (err) {
      informationAlert((err as Error).message, 'error');
    } finally {
      setLoading(false);
    }
  };

  const accessModules = useMemo((): AccessModule[] => {
    if (loginUser) {
      return loginUser?.accessModules;
    } else {
      return [];
    }
  }, [loginUser]);

  useEffect(() => {
    if (getAuthToken()) {
      fetchLoginUserData();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <AppContext.Provider
      value={{
        token,
        loading,
        loginUser,
        accessModules,
        informationAlertMessage,
        onLogin,
        setLoading,
        informationAlert,
        setInformationAlertMessage,
        fetchLoginUserData,
        onloginVerify
      }}
    >
      <Progress id="global_loader" showProgress={loading} />
      {children}
      <InformationAlert
        id={'kengen_notification'}
        text={informationAlertMessage.text}
        open={informationAlertMessage.open}
        severity={informationAlertMessage.severity}
        title={informationAlertMessage.title}
        actionTitle={informationAlertMessage.actionTitle}
        handleClose={handleInformationAlertMessageClose}
        action={informationAlertMessage.action}
      />
    </AppContext.Provider>
  );
};

export { AppProvider, AppContext };
