import { Button, styled } from '@mui/material';

export const StyledButton = styled(Button)<{ spacebetween: string }>`
  text-transform: capitalize;
  min-width: 0px;
  margin-left: ${(props) => {
    if (props.spacebetween === 'true') return '0px';
    else return '0px';
  }};
  margin-right: ${(props) => {
    if (props.spacebetween === 'true') return '10px';
    else return '0px';
  }};
  ${(props) => {
    if (props.size === 'small') return 'height: 30px';
    else return '';
  }};

  span {
    margin-left: 0px;
    margin-right: 0px;
  }
`;
