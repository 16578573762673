import React from 'react';
import Box from '@mui/material/Box';

import { StyledRootScrollbar } from './styles';

// ----------------------------------------------------------------------

type IProps = {
  children: React.ReactNode;
  sx: any;
};

const Scrollbar = ({ children, sx, ...other }: IProps) => {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

  const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

  if (mobile) {
    return (
      <Box sx={{ overflow: 'auto', ...sx }} {...other}>
        {children}
      </Box>
    );
  }

  return (
    <StyledRootScrollbar>
      <Box sx={sx} {...other}>
        {children}
      </Box>
    </StyledRootScrollbar>
  );
};

export default Scrollbar;
