import React from 'react';
import Box from '@mui/material/Box';

import { useResponsive } from 'utils/hooks/use-responsive';

import { NAV, HEADER } from './component/config-layout';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router';

// ----------------------------------------------------------------------

const SPACING = 8;

type IProps = {
  children: React.ReactNode;
};

const Main = ({ children }: IProps) => {
  const lgUp = useResponsive('up', 'lg', 'lg');
  const { pathname } = useLocation();

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        minHeight: 1,
        display: 'flex',
        flexDirection: 'column',
        py: `${HEADER.H_MOBILE + SPACING}px`,
        ...(lgUp && {
          px: 2,
          py: `${HEADER.H_DESKTOP + SPACING}px`,
          width: `calc(100% - ${NAV.WIDTH}px)`
        })
      }}
    >
      {children}
      <Box
        sx={{
          position: 'relative'
          // width: '100%',
          // height: '100vh'
        }}
      >
        {pathname === '/' && (
          <Box
            sx={{
              textAlign: 'center'
              // position: 'absolute',
              // top: '95%',
              // right: '40%'
              // bottom: '10px',
              // zIndex: 1000,
            }}
            mt={2}
          >
            <Typography
              sx={{ textDecoration: 'none', fontSize: '1.25rem' }}
              variant="subtitle2"
              component={'a'}
              href="https://www.pixscale.com"
              target="_blank"
            >
              Pixscale Technologies Pvt. Ltd.
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Main;
