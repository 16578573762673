import { Backdrop, Box } from "@mui/material";
import React, { ReactElement } from "react";

import ProgressPanel from "../ProgressPanel/ProgressPanel";
import { StyledModal } from "./ProgressModal.styles";

type Props = {
  id: string;
  showProgress: boolean;
  text?: string;
};

const ProgressModal = (props: Props): ReactElement => {
  const { id, showProgress, text = "Loading..." } = props;

  return (
    <StyledModal
      id={id}
      open={showProgress}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Box>
        <ProgressPanel id={`${id}_panel`} showProgress={showProgress} text={text} />
      </Box>
    </StyledModal>
  );
};

export default ProgressModal;
