import React, { lazy, Suspense } from 'react';
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
  Outlet
} from 'react-router-dom';
import Layout from 'layouts';
import { checkIsAuthenticated, getAuthToken } from 'utils/helpers';

import ProtectedRoute from './hooks/ProtectedRoute';

export const IndexPage = lazy(() => import('pages'));

export const BfPage = lazy(() => import('pages/bf'));
export const AddEditBfPage = lazy(() => import('pages/bf/components/addEditBf'));

export const GsmPage = lazy(() => import('pages/gsm'));
export const AddEditGsmPage = lazy(()=>import('pages/gsm/components/addEditGsm'))


export const ReelPage = lazy(() => import('pages/reel'));
export const AddEditReelPage = lazy(()=>import('pages/reel/components/addEditReel'));

export const StockPage = lazy(() => import('pages/stock'));
export const AddEditStock = lazy(()=>import('pages/stock/components/addEditStock'));


export const CutomerPage = lazy(() => import('pages/customer'));
export const AddEditCustomer = lazy(()=>import('pages/customer/components/addEditCustomer'));

export const CompanyPage = lazy(() => import('pages/company'));
export const AddEditCompanyPage = lazy(() => import('pages/company/components/addEditCompany'));

export const UserPage = lazy(() => import('pages/user'));
export const AddEditUserPage = lazy(
  () => import('pages/user/components/addEditUser')
);

export const RolePage = lazy(() => import('pages/role'));
export const AddEditRolePage = lazy(
  () => import('pages/role/components/addEditRole')
);

export const OrderPage = lazy(() => import('pages/order'));
export const AddEditOrderPage = lazy(()=>import('pages/order/components/addEditOrder'));

export const OrderReceivePage = lazy(() => import('pages/orderReceive'));
export const EditOrderReceivePage = lazy(() => import('pages/orderReceive/components/editOrderReceive'));

export const OrderTypePage = lazy(() => import('pages/orderType'));
export const AddEditOrderTypePage = lazy(()=>import('pages/orderType/components/addEditOrderType'));

export const GsmCategoryPage = lazy(() => import('pages/gsmCategory'));
export const AddEditGsmCategory = lazy(()=>import('pages/gsmCategory/components/addEditGsmCategory'));

export const RightPage = lazy(() => import('pages/right'));

export const ModulesPage = lazy(() => import('pages/modules'));
export const AddEditModulesPage = lazy(
  () => import('pages/modules/components/addEditModules')
);

export const ProfilePage = lazy(() => import('pages/profile'));
export const LoginPage = lazy(() => import('pages/login'));
// export const ProductsPage = lazy(() => import('src/pages/products'));
export const Page404 = lazy(() => import('pages/notFound'));
export const NotAuthorizedPage = lazy(() => import('pages/notAuthorized'));

export const PrivacyPolicy = lazy(() => import('pages/privacyPolicy'));
export const TermsAndCondition = lazy(() => import('pages/termsAndCondition'));

// ----------------------------------------------------------------------

const Routes = () => {
  const routes = createBrowserRouter([
    {
      path: '/',
      element: (
        <Layout>
          <Suspense>
            <IndexPage />
          </Suspense>
        </Layout>
      ),
      loader: checkIsAuthenticated
    },
    {
      path: '/profile',
      element: (
        <Layout>
          <Suspense>
            <Outlet />
          </Suspense>
        </Layout>
      ),
      loader: checkIsAuthenticated,
      children: [{ element: <ProfilePage />, index: true }]
    },
    {
      path: '/bf',
      element: (
        <Layout>
          <Suspense>
            <Outlet/>
          </Suspense>
        </Layout>
      ),
      loader: checkIsAuthenticated,
      children: [
        {
          index: true,
          element: <ProtectedRoute Component={<BfPage />}/>
        },
        {
          path: '/bf/:mode',
          element: <ProtectedRoute Component={<AddEditBfPage />} />
        },
        {
          path: '/bf/:mode/:id',
          element: <ProtectedRoute Component={<AddEditBfPage />} />
        }
      ]
    },
    {
      path: '/gsm',
      element: (
        <Layout>
          <Suspense>
            <Outlet/>
          </Suspense>
        </Layout>
      ),
      loader: checkIsAuthenticated,
      children: [
        {
          index: true,
          element: <ProtectedRoute Component={<GsmPage />}/>
        },
        {
          path: '/gsm/:mode',
          element: <ProtectedRoute Component={<AddEditGsmPage />} />
        },
        {
          path: '/gsm/:mode/:id',
          element: <ProtectedRoute Component={<AddEditGsmPage />} />
        }
      ]
    },
    {
      path: '/reel',
      element: (
        <Layout>
          <Suspense>
            <Outlet/>
          </Suspense>
        </Layout>
      ),
      loader: checkIsAuthenticated,
      children: [
        {
          index: true,
          element: <ProtectedRoute Component={<ReelPage />}/>
        },
        {
          path: '/reel/:mode',
          element: <ProtectedRoute Component={<AddEditReelPage />} />
        },
        {
          path: '/reel/:mode/:id',
          element: <ProtectedRoute Component={<AddEditReelPage />} />
        }
      ]
    },
    {
      path: '/stock',
      element: (
        <Layout>
          <Suspense>
            <Outlet/>
          </Suspense>
        </Layout>
      ),
      loader: checkIsAuthenticated,
      children: [
        {
          index: true,
          element: <ProtectedRoute Component={<StockPage />}/>
        },
        {
          path: '/stock/:mode',
          element: <ProtectedRoute Component={<AddEditStock />} />
        },
        {
          path: '/stock/:mode/:id',
          element: <ProtectedRoute Component={<AddEditStock />} />
        }
      ]
    },
    {
      path: '/customer',
      element: (
        <Layout>
          <Suspense>
            <Outlet/>
          </Suspense>
        </Layout>
      ),
      loader: checkIsAuthenticated,
      children: [
        {
          index: true,
          element: <ProtectedRoute Component={<CutomerPage />}/>
        },
        {
          path: '/customer/:mode',
          element: <ProtectedRoute Component={<AddEditCustomer />} />
        },
        {
          path: '/customer/:mode/:id',
          element: <ProtectedRoute Component={<AddEditCustomer />} />
        }
      ]
    },
    {
      path: '/order',
      element: (
        <Layout>
          <Suspense>
            <Outlet/>
          </Suspense>
        </Layout>
      ),
      loader: checkIsAuthenticated,
      children: [
        {
          index: true,
          element: <ProtectedRoute Component={<OrderPage />}/>
        },
        {
          path: '/order/:mode',
          element: <ProtectedRoute Component={<AddEditOrderPage />} />
        },
        {
          path: '/order/:mode/:id',
          element: <ProtectedRoute Component={<AddEditOrderPage />} />
        }
      ]
    },
    {
      path: '/order-receive',
      element: (
        <Layout>
          <Suspense>
            <Outlet/>
          </Suspense>
        </Layout>
      ),
      loader: checkIsAuthenticated,
      children: [
        {
          index: true,
          element: <ProtectedRoute Component={<OrderReceivePage />}/>
        },
        // {
        //   path: '/order/:mode',
        //   element: <ProtectedRoute Component={<AddEditOrderPage />} />
        // },
        {
          path: '/order-receive/:mode/:id',
          element: <ProtectedRoute Component={<EditOrderReceivePage />} />
        }
      ]
    },
    {
      path: '/gsmcategory',
      element: (
        <Layout>
          <Suspense>
            <Outlet/>
          </Suspense>
        </Layout>
      ),
      loader: checkIsAuthenticated,
      children: [
        {
          index: true,
          element: <ProtectedRoute Component={<GsmCategoryPage />}/>
        },
        {
          path: '/gsmcategory/:mode',
          element: <ProtectedRoute Component={<AddEditGsmCategory />} />
        },
        {
          path: '/gsmcategory/:mode/:id',
          element: <ProtectedRoute Component={<AddEditGsmCategory />} />
        }
      ]
    },

    {
      path: '/ordertype',
      element: (
        <Layout>
          <Suspense>
            <Outlet/>
          </Suspense>
        </Layout>
      ),
      loader: checkIsAuthenticated,
      children: [
        {
          index: true,
          element: <ProtectedRoute Component={<OrderTypePage />}/>
        },
        {
          path: '/ordertype/:mode',
          element: <ProtectedRoute Component={<AddEditOrderTypePage />} />
        },
        {
          path: '/ordertype/:mode/:id',
          element: <ProtectedRoute Component={<AddEditOrderTypePage />} />
        }
      ]
    },
    {
      path: '/company',
      element: (
        <Layout>
          <Suspense>
            <Outlet/>
          </Suspense>
        </Layout>
      ),
      loader: checkIsAuthenticated,
      children: [
        {
          index: true,
          element: <ProtectedRoute Component={<CompanyPage />}/>
        },
        {
          path: '/company/:mode',
          element: <ProtectedRoute Component={<AddEditCompanyPage />} />
        },
        {
          path: '/company/:mode/:id',
          element: <ProtectedRoute Component={<AddEditCompanyPage />} />
        }
      ]
    },
    {
      path: '/users',
      element: (
        <Layout>
          <Suspense>
            <Outlet />
          </Suspense>
        </Layout>
      ),
      loader: checkIsAuthenticated,
      children: [
        {
          index: true,
          element: <ProtectedRoute Component={<UserPage />} />
        },
        {
          path: '/users/:mode',
          element: <ProtectedRoute Component={<AddEditUserPage />} />
        },
        {
          path: '/users/:mode/:id',
          element: <ProtectedRoute Component={<AddEditUserPage />} />
        }
      ]
    },
    {
      path: '/roles',
      element: (
        <Layout>
          <Suspense>
            <Outlet />
          </Suspense>
        </Layout>
      ),
      loader: checkIsAuthenticated,
      children: [
        {
          index: true,
          element: <ProtectedRoute Component={<RolePage />} />
        },
        {
          path: '/roles/:mode',
          element: <ProtectedRoute Component={<AddEditRolePage />} />
        },
        {
          path: '/roles/:mode/:id',
          element: <ProtectedRoute Component={<AddEditRolePage />} />
        }
      ]
    },
    {
      path: '/rights',
      element: (
        <Layout>
          <Suspense>
            <Outlet />
          </Suspense>
        </Layout>
      ),
      loader: checkIsAuthenticated,
      children: [
        {
          index: true,
          element: <ProtectedRoute Component={<RightPage />} />
        }
      ]
    },
    {
      path: '/modules',
      element: (
        <Layout>
          <Suspense>
            <Outlet />
          </Suspense>
        </Layout>
      ),
      loader: checkIsAuthenticated,
      children: [
        {
          index: true,
          element: <ProtectedRoute Component={<ModulesPage />} />
        },
        {
          path: '/modules/:mode',
          element: <ProtectedRoute Component={<AddEditModulesPage />} />
        },
        {
          path: '/modules/:mode/:id',
          element: <ProtectedRoute Component={<AddEditModulesPage />} />
        }
      ]
    },
    {
      path: 'privacy-policy',
      element: (
        <Suspense>
          <PrivacyPolicy/>
        </Suspense>
      )
    },
    {
      path: 'terms-and-condition',
      element: (
        <Suspense>
          <TermsAndCondition/>
        </Suspense>
      )
    },
    {
      path: 'login',
      element: !getAuthToken() ? (
        <Suspense>
          <LoginPage />
        </Suspense>
      ) : (
        <Navigate to={'/'} replace={true} />
      )
    },
    {
      path: '404',
      element: <Page404 />
    },
    {
      path: 'not-authorized',
      element: <NotAuthorizedPage />
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />
    }
  ]);

  return <RouterProvider router={routes} />;
};

export default Routes;
