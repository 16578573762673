import React, { ReactElement } from 'react';

import Fade from '@mui/material/Fade';
import { ButtonPropsSizeOverrides } from '@mui/material/Button';
import { OverridableStringUnion } from '@mui/types';

import {
  BUTTON_COLOR_OPTIONS,
  BUTTON_STYLE_VARIANTS
} from 'utils/constants/constants';
import { StyledButton } from './Button.styles';

type Props = {
  id: string;
  icon?: any;
  variant?: BUTTON_STYLE_VARIANTS;
  text: string;
  color: BUTTON_COLOR_OPTIONS;
  size?: OverridableStringUnion<
    'small' | 'medium' | 'large',
    ButtonPropsSizeOverrides
  >;
  hidden?: boolean;
  onClick?: any;
  fullWidth?: boolean;
  disabled?: boolean;
  addSpaceBetweenButtons?: boolean;
  type?: 'submit' | 'reset' | 'button';
};

const ButtonComponent = (props: Props): ReactElement => {
  const {
    id,
    icon = null,
    text = '',
    color,
    size,
    hidden = false,
    variant = 'contained',
    onClick,
    fullWidth = false,
    disabled = false,
    addSpaceBetweenButtons = false,
    type
  } = props;

  return (
    <Fade in={!hidden}>
      <StyledButton
        id={id}
        color={color}
        variant={variant}
        disableElevation={true}
        startIcon={icon}
        onClick={onClick}
        size={size}
        fullWidth={fullWidth}
        type={type}
        disabled={disabled}
        spacebetween={addSpaceBetweenButtons.toString()}
      >
        {text}
      </StyledButton>
    </Fade>
  );
};

export default ButtonComponent;
